import { Card } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { useRef } from 'react';
import { createTheme, ThemeProvider } from '@mui/material';

import { getHospitalDashTableData } from '../../../queries/overview';
import { getFormattedNumber } from '../../../utils/currency.utils';
import {
  SmallCurved,
  SmallFilterIcon,
  SmallInfoIcon
} from '../../../icons/icons';

import Link from 'next/link';
import CustomPagination from '../../shared/CustomPagination';
import MaterialTable from '@material-table/core';
import { useQuery } from 'react-query';

const LinkToHospital = ({ children, rowData, ...props }) => {
  return (
    <Link
      href={`/overview/hospital?q=${rowData?.id}`}
      style={{ textDecoration: 'none', color: '#000' }}
      {...props}
    >
      {children}
    </Link>
  );
};

export const ColumnHeading = ({ title, Icon, ...props }) => {
  const { classes } = useStyles();

  return (
    <span className={classes.tableHeading} {...props} style={{ position: 'relative' }}>
      {title}
      <span style={{ paddingLeft: '4px', marginTop: '3px' }}>
        <Icon />
      </span>
    </span>
  );
};

const HospitalsTable = props => {
  const healthSystemId = props.healthSystemId;
  const { data, isFetching, isLoading } = useQuery(
    ['getHospitalDashTableData', healthSystemId],
    () => getHospitalDashTableData({ healthSystemId: healthSystemId }),
    {
      enabled: !!healthSystemId
    }
  );

  const defaultMaterialTheme = createTheme();
  const { classes } = useStyles();

  const cellStyle = {
    border: 'none',
    padding: '10px 15px'
  };

  const headerStyle = {
    whiteSpace: 'nowrap',
    wordBreak: 'break-word',
    textOverflow: 'ellipsis'
  };

  const widthRef = useRef(null);

  return (
    <Card className={classes.card} data-cy="hospitals">
      <ThemeProvider theme={defaultMaterialTheme}>
        <MaterialTable
          style={{ boxShadow: 'none' }}
          // icons={tableIcons}
          isLoading={isFetching || isLoading}
          icons={{ SortArrow: () => <div /> }}
          columns={[
            {
              title: (
                <ColumnHeading title={'Hospital'} data-cy="Hospital" Icon={SmallFilterIcon} />
              ),
              field: 'name',
              headerStyle: { borderRadius: '20px 0 0 20px' },
              cellStyle: { ...cellStyle, borderRadius: '20px 0 0 20px' },
              render: rowData => (
                <LinkToHospital data-cy={"Hospital"} rowData={rowData}>
                  {rowData?.name}
                </LinkToHospital>
              )
            },
            {
              title: (
                <ColumnHeading title={'Opportunities'} data-cy="Opportunities" Icon={SmallInfoIcon} />
              ),
              field: 'opportunitiesCount',
              cellStyle,
              headerStyle,
              render: rowData => (
                <LinkToHospital data-cy="Opportunities" rowData={rowData}>
                  <span>
                    <span className={classes.cellData}>
                      {getFormattedNumber(rowData?.opportunitiesCount)}
                    </span>{' '}
                    <span className={classes?.cellSubData}>
                      ({getFormattedNumber(rowData?.opportunitiesSavings)})
                    </span>
                  </span>
                </LinkToHospital>
              )
            },

            {
              title: (
                <ColumnHeading title={'Utilization'} data-cy="Utilization" Icon={SmallInfoIcon} />
              ),
              field: 'utilizationCount',
              headerStyle,
              cellStyle,
              render: rowData => (
                <LinkToHospital data-cy="Utilization" rowData={rowData}>
                  <span>
                    <span className={classes.cellData}>
                      {getFormattedNumber(rowData?.utilizationCount)}
                    </span>{' '}
                    <span className={classes?.cellSubData}>
                      ({getFormattedNumber(rowData?.utilizationSavings)})
                    </span>
                  </span>
                </LinkToHospital>
              )
            },
            {
              title: (
                <ColumnHeading title={'Physicians'} data-cy="Physicians" Icon={SmallInfoIcon} />
              ),
              field: 'physiciansCount',
              cellStyle,
              headerStyle,
              render: rowData => (
                <LinkToHospital data-cy="Physicians" rowData={rowData}>
                  <span className={classes.cellData}>
                    {getFormattedNumber(rowData?.physiciansCount)}
                  </span>
                </LinkToHospital>
              )
            },
            {
              title: (
                <ColumnHeading
                  title={'Medication Spend'}
                  Icon={SmallInfoIcon}
                  data-cy="Medication Spend"
                />
              ),
              field: 'medicationSpend',
              cellStyle,
              headerStyle,
              render: rowData => (
                <LinkToHospital data-cy="Medication Spend" rowData={rowData}>
                  <span
                    className={classes.cellData}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100px',
                      lineHeight: rowData?.medicationSpend > 100 ? 1 : 2
                    }}
                  >
                    <span style={{ textAlign: 'center' }}>
                      ${getFormattedNumber(rowData?.medicationSpend)}
                    </span>
                    {rowData?.medicationSpend > 100 && (
                      <span style={{ width: '100px' }}>
                        <SmallCurved width={100} />
                      </span>
                    )}
                  </span>
                </LinkToHospital>
              )
            },
            {
              title: (
                <ColumnHeading title={'New patients'} data-cy="New patients" Icon={SmallInfoIcon} />
              ),
              field: 'newPatients',
              cellStyle,
              headerStyle,
              render: rowData => (
                <LinkToHospital data-cy="New patients" rowData={rowData}>
                  <span className={classes.cellData}>
                    {getFormattedNumber(rowData?.newPatients)}
                  </span>
                </LinkToHospital>
              )
            },
            {
              title: (
                <ColumnHeading title={'Length of stay'} data-cy="Length of stay" Icon={SmallInfoIcon} />
              ),
              field: 'lengthOfStay',
              headerStyle: { ...headerStyle, borderRadius: '0 20px 20px 0' },
              cellStyle: { ...cellStyle, borderRadius: '0 20px 20px 0' },
              render: rowData => (
                <LinkToHospital data-cy="Length of stay" rowData={rowData}>
                  {' '}
                  <span className={classes.cellData}>
                    {getFormattedNumber(rowData?.lengthOfStay)}
                  </span>
                </LinkToHospital>
              )
            }
          ]}
          data={
            (data &&
              data?.healthSystemDashHospitalTable && [
                // ...data?.healthSystemDashHospitalTable,
                // ...data?.healthSystemDashHospitalTable,
                ...data?.healthSystemDashHospitalTable
              ]) ||
            []
          }
          options={{
            paging: true,
            pageSize: 6, // make initial page size
            emptyRowsWhenPaging: false, // To avoid of having empty rows
            pageSizeOptions: [6],
            draggable: false,
            headerStyle: {
              backgroundColor: '#397ed0',
              color: '#FFF',
              padding: '8px 10px',
              border: 'none'
            },
            rowStyle: x => {
              if (x.tableData.id % 2) {
                return {
                  backgroundColor: '#eff5ff',
                  height: 20
                };
              }
            }
          }}
          components={{
            Toolbar: props => (
              <div className={classes.toolbar}>
                <div className={classes.title}>Hospitals</div>
                <div className={classes.button} data-cy="view-all">
                  View All
                </div>
              </div>
            ),
            Pagination: props => {
              return <CustomPagination props={props} />;
            }
          }}
        />
      </ThemeProvider>
    </Card>
  );
};

const useStyles = makeStyles()(theme => {
  return {
    root: {
      '& .MuiPaginationItem-root': {
        border: 'none'
      },
      '& .MuiPaginationItem-root.Mui-selected': {
        backgroundColor: '#387ED0',
        color: '#fff'
      }
    },
    tableHeading: {
      display: 'flex',
      alignItems: 'center'
    },
    card: {
      padding: '0 20px'
    },
    table: {
      border: 'none'
    },
    toolbar: {
      padding: '0 5px',
      height: 70,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    title: {
      fontSize: 20,
      fontWeight: '500',
      color: '#465671'
    },
    tabContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: 10,
      overflow: 'hidden'
    },
    cellData: {
      color: '#465671',
      fontWeight: 500,
      fontSize: '18px',
      whiteSpace: 'nowrap',
      wordBreak: 'break-word',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    },
    cellSubData: {
      fontSize: '14px',
      fontStyle: 'italic',
      fontWeight: 500,
      lineHeight: '16px',
      letterSpacing: '0em',
      color: '#387ED0'
    },
    tabItem: {
      padding: '10px 10px',
      fontSize: '1rem',
      minWidth: 140,
      cursor: 'pointer',
      backgroundColor: '#eff5ff',
      color: '#397ed0',
      textAlign: 'center',
      fontWeight: '500'
    },
    activeTab: {
      backgroundColor: '#397ed0',
      color: '#fff'
    },
    button: {
      padding: '5px 12px',
      cursor: 'pointer',
      width: 90,
      backgroundColor: '#eff5ff',
      color: '#397ed0',
      textAlign: 'center',
      borderRadius: 10
    },
    profile: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    }
  };
});

export default HospitalsTable;
