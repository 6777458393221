import LengthOfStayGraphOverViewHomePage from './OverviewPageCharts/LengthOfStayGraphOverViewHomePage';
import MedicationSpendGraphOverViewHomePage from './OverviewPageCharts/MedicationSpendGraphOverViewHomePage';
import NewAdmissionGraphOverViewHomePage from './OverviewPageCharts/NewAdmissionGraphOverViewHomePage';
import PatientVolumeGraphOverViewHomePage from './OverviewPageCharts/PatientVolumeGraphOverViewHomePage';
// import Chart from "react-apexcharts";

const OverviewAllCharts = ({ hospitalOptions, topThreeHospitals }) => {
  return (
    <>
      <MedicationSpendGraphOverViewHomePage
        hospitalOptions={hospitalOptions}
        topThreeHospitals={topThreeHospitals}
      />
      <PatientVolumeGraphOverViewHomePage
        hospitalOptions={hospitalOptions}
        topThreeHospitals={topThreeHospitals}
      />

      <LengthOfStayGraphOverViewHomePage
        hospitalOptions={hospitalOptions}
        topThreeHospitals={topThreeHospitals}
      />

      <NewAdmissionGraphOverViewHomePage
        hospitalOptions={hospitalOptions}
        topThreeHospitals={topThreeHospitals}
      />
    </>
  );
};

export default OverviewAllCharts;
