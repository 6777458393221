import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { gettLengthOfStayGraphData } from '../../../../queries/overview';
import {
  getAllDays,
  getAllMonths,
  getAllWeeks,
  getFormattedDataForGraphsOverviewHomePage,
  getLengthAccordingToSelectedHospital,
  getParamsForGraphsOverviewHomePage,
  getTopThreeHospitals,
  handleRangeForGraphsOverviewHomePage
} from '../../../../utils/overview.utils';
import ApexBarChartDistributed from '../ApexBarChartDistributed';

const LengthOfStayGraphOverViewHomePage = ({
  hospitalOptions,
  topThreeHospitals
}) => {
  const [
    apiTobeCalledForHospitalsLengthOfStay,
    setApiToBeCalledForHospitalsLengthOfStay
  ] = useState([...topThreeHospitals]);
  //count params should be multiple of 12

  const barChartsTotalGraphs = getLengthAccordingToSelectedHospital(
    apiTobeCalledForHospitalsLengthOfStay
  );
 
  const allWeeks = getAllWeeks({
    count: 120,
    multipleOf: 20
  });
  const allMonths = getAllMonths({
    count: 24,
    multipleOf: barChartsTotalGraphs
  });
  const allDays = getAllDays({
    count: 720,
    multipleOf: barChartsTotalGraphs
  });
  const initialRange = [0, barChartsTotalGraphs];
  const [lengthOfStayRange, setLengthOfStayRange] = useState(initialRange);

  const [selectedRangeLengthOfStudy, setSelectedRangeLengthOfStudy] =
    useState('Daily');

  useEffect(() => {
    setLengthOfStayRange([0, barChartsTotalGraphs]);
  }, [apiTobeCalledForHospitalsLengthOfStay, selectedRangeLengthOfStudy]);

  const {
    data: lengthOfStayResult,
    isFetching,
    isLoading
  } = useQuery(
    [
      'gettLengthOfStayGraphData',
      apiTobeCalledForHospitalsLengthOfStay,
      selectedRangeLengthOfStudy,
      lengthOfStayRange
    ],
    () =>
      gettLengthOfStayGraphData(
        getParamsForGraphsOverviewHomePage(
          apiTobeCalledForHospitalsLengthOfStay,
          selectedRangeLengthOfStudy,
          lengthOfStayRange,
          allWeeks,
          allMonths,
          allDays
        )
      ),
    {
      enabled:
        !!apiTobeCalledForHospitalsLengthOfStay?.length && !!lengthOfStayRange
    }
  );

  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <ApexBarChartDistributed
          originalTopThreeHospitals={topThreeHospitals}
          data={{ title: 'Length Of Stay' }}
          topThreeHospitals={getTopThreeHospitals(
            apiTobeCalledForHospitalsLengthOfStay
          )}
          id="stay-length"
          selectedRange={selectedRangeLengthOfStudy}
          setSelectedRange={setSelectedRangeLengthOfStudy}
          hospitalOptions={hospitalOptions}
          chartData={getFormattedDataForGraphsOverviewHomePage(
            lengthOfStayResult?.lengthOfStayDashBoardChart?.chart?.sort(
              (a, b) => parseFloat(b?.date) - parseFloat(a?.date)
            ),
            apiTobeCalledForHospitalsLengthOfStay,
            lengthOfStayRange,
            selectedRangeLengthOfStudy,
            allWeeks,
            allMonths,
            allDays
          )}
          fetching={isFetching || isLoading}
          handleRange={handleRangeForGraphsOverviewHomePage}
          range={lengthOfStayRange}
          setRange={setLengthOfStayRange}
          chartDataLength={
            selectedRangeLengthOfStudy === 'Daily'
              ? allDays.length
              : selectedRangeLengthOfStudy === 'Weekly'
              ? allWeeks.length
              : allMonths.length
          }
          selectedHospitals={apiTobeCalledForHospitalsLengthOfStay}
          setOnChangeHospitals={setApiToBeCalledForHospitalsLengthOfStay}
          stepUp={initialRange[1]}
          initialRange={initialRange}
        />
      </Grid>
    </>
  );
};

export default LengthOfStayGraphOverViewHomePage;
