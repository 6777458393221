import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getAdmissionGraphData } from '../../../../queries/overview';
import {
  getAllDays,
  getAllMonths,
  getAllWeeks,
  getFormattedDataForGraphsOverviewHomePage,
  getLengthAccordingToSelectedHospital,
  getParamsForGraphsOverviewHomePage,
  getTopThreeHospitals,
  handleRangeForGraphsOverviewHomePage
} from '../../../../utils/overview.utils';
import ApexBarChartDistributed from '../ApexBarChartDistributed';

const NewAdmissionGraphOverViewHomePage = ({
  hospitalOptions,
  topThreeHospitals,
  healthSystemId,
  hospitalId,
  frequency
}) => {
  const [
    apiTobeCalledForHospitalsNewAdmission,
    setApiToBeCalledForHospitalsNewAdmission
  ] = useState([...topThreeHospitals]);

  const barChartsTotalGraphs = getLengthAccordingToSelectedHospital(
    apiTobeCalledForHospitalsNewAdmission
  );

  //count params should be multiple of 12
  const allWeeks = getAllWeeks({
    count: 120,
    multipleOf: barChartsTotalGraphs
  });
  const allMonths = getAllMonths({
    count: 24,
    multipleOf: barChartsTotalGraphs
  });
  const allDays = getAllDays({
    count: 720,
    multipleOf: barChartsTotalGraphs
  });
  const initialRange = [
    0,
    getLengthAccordingToSelectedHospital(apiTobeCalledForHospitalsNewAdmission)
  ];
  const [newAdmissionRange, setNewAdmissionRange] = useState(initialRange);

  const [selectedRangeNewAdmission, setSelectedRangeNewAdmission] =
    useState('Daily');

  useEffect(() => {
    setNewAdmissionRange([0, barChartsTotalGraphs]);
  }, [apiTobeCalledForHospitalsNewAdmission, selectedRangeNewAdmission]);

  const {
    data: admissionResult,
    isFetching,
    isLoading
  } = useQuery(
    [
      'getAdmissionGraphData',
      apiTobeCalledForHospitalsNewAdmission,
      selectedRangeNewAdmission,
      newAdmissionRange
    ],
    () =>
      getAdmissionGraphData(
        getParamsForGraphsOverviewHomePage(
          apiTobeCalledForHospitalsNewAdmission,
          selectedRangeNewAdmission,
          newAdmissionRange,
          allWeeks,
          allMonths,
          allDays
        )
      ),
    {
      enabled:
        !!apiTobeCalledForHospitalsNewAdmission?.length &&
        !!selectedRangeNewAdmission
    }
  );

  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <ApexBarChartDistributed
          originalTopThreeHospitals={topThreeHospitals}
          data={{ title: 'Discharged Patients' }}
          healthSystemId={healthSystemId}
          hospitalId={hospitalId}
          frequency={frequency}
          topThreeHospitals={getTopThreeHospitals(
            apiTobeCalledForHospitalsNewAdmission
          )}
          id="admission"
          selectedRange={selectedRangeNewAdmission}
          setSelectedRange={setSelectedRangeNewAdmission}
          hospitalOptions={hospitalOptions}
          chartData={getFormattedDataForGraphsOverviewHomePage(
            admissionResult?.newAdmissionsDashBoardChart?.chart?.sort(
              (a, b) => parseFloat(b?.date) - parseFloat(a?.date)
            ),
            apiTobeCalledForHospitalsNewAdmission,
            newAdmissionRange,
            selectedRangeNewAdmission,
            allWeeks,
            allMonths,
            allDays
          )}
          // chartData = {medSpendData}
          fetching={(isFetching, isLoading)}
          handleRange={handleRangeForGraphsOverviewHomePage}
          range={newAdmissionRange}
          setRange={setNewAdmissionRange}
          chartDataLength={
            selectedRangeNewAdmission === 'Daily'
              ? allDays.length
              : selectedRangeNewAdmission === 'Weekly'
              ? allWeeks.length
              : allMonths.length
          }
          selectedHospitals={apiTobeCalledForHospitalsNewAdmission}
          setOnChangeHospitals={setApiToBeCalledForHospitalsNewAdmission}
          stepUp={initialRange[1]}
          initialRange={initialRange}
        />
      </Grid>
    </>
  );
};

export default NewAdmissionGraphOverViewHomePage;
