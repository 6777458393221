import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getMedicationSpendGraphData } from '../../../../queries/overview';
import {
  getAllDays,
  getAllMonths,
  getAllWeeks,
  getFormattedDataForGraphsOverviewHomePage,
  getLengthAccordingToSelectedHospital,
  getParamsForGraphsOverviewHomePage,
  getTopThreeHospitals,
  handleRangeForGraphsOverviewHomePage
} from '../../../../utils/overview.utils';
import ApexBarChartDistributed from '../ApexBarChartDistributed';

const MedicationSpendGraphOverViewHomePage = ({
  hospitalOptions,
  topThreeHospitals
}) => {
  const [
    apiTobeCalledForHospitalsMedicationSpend,
    setApiToBeCalledForHospitalsMedicationSpend
  ] = useState([...topThreeHospitals]);
  const barChartsTotalGraphs = getLengthAccordingToSelectedHospital(
    apiTobeCalledForHospitalsMedicationSpend
  );
  //count params should be multiple of 12
  const allWeeks = getAllWeeks({
    count: 120,
    multipleOf: barChartsTotalGraphs
  });
  const allMonths = getAllMonths({
    count: 24,
    multipleOf: barChartsTotalGraphs
  });
  const allDays = getAllDays({
    count: 720,
    multipleOf: barChartsTotalGraphs
  });
  const initialRange = [0, barChartsTotalGraphs];
  const [medicationRange, setMedicationRange] = useState(initialRange);
  const [selectedRangeMedicalSpend, setSelectedRangeMedicalSpend] =
    useState('Weekly');

  useEffect(() => {
    setMedicationRange([0, barChartsTotalGraphs]);
  }, [apiTobeCalledForHospitalsMedicationSpend, selectedRangeMedicalSpend]);

  const {
    data: medicationSpendResult,
    isFetching,
    isLoading
  } = useQuery(
    [
      'getMedicationSpendGraphData',
      apiTobeCalledForHospitalsMedicationSpend,
      selectedRangeMedicalSpend,
      medicationRange
    ],
    () =>
      getMedicationSpendGraphData(
        getParamsForGraphsOverviewHomePage(
          apiTobeCalledForHospitalsMedicationSpend,
          selectedRangeMedicalSpend,
          medicationRange,
          allWeeks,
          allMonths,
          allDays
        )
      ),
    {
      enabled:
        !!apiTobeCalledForHospitalsMedicationSpend?.length &&
        !!selectedRangeMedicalSpend
    }
  );

  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <ApexBarChartDistributed
          originalTopThreeHospitals={topThreeHospitals}
          data={{ title: 'Medication Spend' }}
          topThreeHospitals={getTopThreeHospitals(
            apiTobeCalledForHospitalsMedicationSpend
          )}
          id="medication"
          selectedRange={selectedRangeMedicalSpend}
          setSelectedRange={setSelectedRangeMedicalSpend}
          hospitalOptions={hospitalOptions}
          chartData={getFormattedDataForGraphsOverviewHomePage(
            medicationSpendResult?.topMedicationSpendDashBoardChart?.chart?.sort(
              (a, b) => parseFloat(b?.date) - parseFloat(a?.date)
            ),
            apiTobeCalledForHospitalsMedicationSpend,
            medicationRange,
            selectedRangeMedicalSpend,
            allWeeks,
            allMonths,
            allDays
          )}
          // chartData = {medSpendData}
          fetching={isFetching || isLoading}
          handleRange={handleRangeForGraphsOverviewHomePage}
          range={medicationRange}
          setRange={setMedicationRange}
          chartDataLength={
            selectedRangeMedicalSpend === 'Daily'
              ? allDays.length
              : selectedRangeMedicalSpend === 'Weekly'
                ? allWeeks.length
                : allMonths.length
          }
          selectedHospitals={apiTobeCalledForHospitalsMedicationSpend}
          setOnChangeHospitals={setApiToBeCalledForHospitalsMedicationSpend}
          prefix="$"
          stepUp={initialRange[1]}
          initialRange={initialRange}
        />
      </Grid>
    </>
  );
};

export default MedicationSpendGraphOverViewHomePage;
