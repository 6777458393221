import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getPatientVolumeGraphData } from '../../../../queries/overview';
import {
  getAllDays,
  getAllMonths,
  getAllWeeks,
  getFormattedDataForGraphsOverviewHomePage,
  getLengthAccordingToSelectedHospital,
  getParamsForGraphsOverviewHomePage,
  getTopThreeHospitals,
  handleRangeForGraphsOverviewHomePage
} from '../../../../utils/overview.utils';
import ApexBarChartDistributed from '../ApexBarChartDistributed';

const PatientVolumeGraphOverViewHomePage = ({
  hospitalOptions,
  topThreeHospitals
}) => {
  const [
    apiTobeCalledForHospitalsPatientVolume,
    setApiToBeCalledForHospitalsPatientVolume
  ] = useState([...topThreeHospitals]);
  const barChartsTotalGraphs = getLengthAccordingToSelectedHospital(
    apiTobeCalledForHospitalsPatientVolume
  );
  //count params should be multiple of 12
  const allWeeks = getAllWeeks({
    count: 120,
    multipleOf: barChartsTotalGraphs
  });
  const allMonths = getAllMonths({
    count: 24,
    multipleOf: barChartsTotalGraphs
  });
  const allDays = getAllDays({
    count: 720,
    multipleOf: barChartsTotalGraphs
  });
  const initialRange = [
    0,
    getLengthAccordingToSelectedHospital(apiTobeCalledForHospitalsPatientVolume)
  ];
  const [patientVolumeRange, setPatientVolumeRange] = useState(initialRange);

  const [selectedRangePatientVolume, setSelectedRangePatientVolume] =
    useState('Weekly');

  useEffect(() => {
    setPatientVolumeRange([0, barChartsTotalGraphs]);
  }, [apiTobeCalledForHospitalsPatientVolume, selectedRangePatientVolume]);

  const {
    data: patientVolumeResult,
    isFetching,
    isLoading
  } = useQuery(
    [
      'getPatientVolumeGraphData',
      apiTobeCalledForHospitalsPatientVolume,
      selectedRangePatientVolume,
      patientVolumeRange
    ],
    () =>
      getPatientVolumeGraphData(
        getParamsForGraphsOverviewHomePage(
          apiTobeCalledForHospitalsPatientVolume,
          selectedRangePatientVolume,
          patientVolumeRange,
          allWeeks,
          allMonths,
          allDays
        )
      ),
    {
      enabled:
        !!apiTobeCalledForHospitalsPatientVolume?.length &&
        !!selectedRangePatientVolume
    }
  );

  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <ApexBarChartDistributed
          originalTopThreeHospitals={topThreeHospitals}
          data={{ title: 'Patient Volume' }}
          topThreeHospitals={getTopThreeHospitals(
            apiTobeCalledForHospitalsPatientVolume
          )}
          id="patient"
          selectedRange={selectedRangePatientVolume}
          setSelectedRange={setSelectedRangePatientVolume}
          hospitalOptions={hospitalOptions}
          chartData={getFormattedDataForGraphsOverviewHomePage(
            patientVolumeResult?.patientLoadDashBoardChart?.chart?.sort(
              (a, b) => parseFloat(b?.date) - parseFloat(a?.date)
            ),
            apiTobeCalledForHospitalsPatientVolume,
            patientVolumeRange,
            selectedRangePatientVolume,
            allWeeks,
            allMonths,
            allDays
          )}
          // chartData = {medSpendData}
          fetching={isFetching || isLoading}
          handleRange={handleRangeForGraphsOverviewHomePage}
          range={patientVolumeRange}
          setRange={setPatientVolumeRange}
          chartDataLength={
            selectedRangePatientVolume === 'Daily'
              ? allDays.length
              : selectedRangePatientVolume === 'Weekly'
              ? allWeeks.length
              : allMonths.length
          }
          selectedHospitals={apiTobeCalledForHospitalsPatientVolume}
          setOnChangeHospitals={setApiToBeCalledForHospitalsPatientVolume}
          stepUp={initialRange[1]}
          initialRange={initialRange}
        />
      </Grid>
    </>
  );
};

export default PatientVolumeGraphOverViewHomePage;
