import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import Grid from '@mui/material/Grid';
import MainLayout from '../../layout/MainLayout';
import FilterComponent from '../../components/shared/FilterComponent';
import MedicationSpend from '../../components/overview/common/MedicationSpend';
import MedicationSpendPerPatient from '../../components/overview/common/MedicationSpendPerPatient';
import NewPatient from '../../components/overview/common/NewAdmission';
import SavingOpportunity from '../../components/overview/common/SavingOpportunity';
import UtilizationTrend from '../../components/overview/common/UtilizationTrend';
import PhysicianTrend from '../../components/overview/common/PhysicianTrend';
import OverviewInsights from '../../components/overview/healthsystem/insights';
import OverviewAllCharts from '../../components/dashboard/Overview/OverviewAllCharts';
import HospitalsTable from '../../components/dashboard/Overview/Hospitals';
import { getHealthSystem, getHospitalsList } from '../../queries/overview';
import CircularLoader from '../../components/shared/CircularLoader';
import { redirectToPage } from '../../utils/overview.utils';
// import posthog from 'posthog-js';
import Head from 'next/head';
import FromMyActivity from '../../components/shared/FromMyActivity';

const OverviewHealthSystem = props => {
  const router = useRouter();
  const { hospitalOptionsData, healthSystem } = props;
  const healthSystemId = healthSystem?.value;
  const { from } = router.query;
  const fromActivity = from === 'my-activity';
  const isFetching = false;
  const isLoading = false;

  const getTopThreeHospitals = () => {
    const hospitals = [];

    const allHospitalOptions = hospitalOptionsData?.hospitalOption || [];
    if (allHospitalOptions?.length > 3) {
      for (let i = 0; i < allHospitalOptions?.length; i++) {
        hospitals.push(allHospitalOptions[i]);
      }

      return hospitals;
    }

    return allHospitalOptions;
  };
  const topThreeHospitals = getTopThreeHospitals();

  const navs = {
    home: 'Home',
    tabs: [
      { label: 'Health System', link: '/overview' },
      { label: 'Hospital', link: '/overview/hospital' }
    ]
  };

  const handleSearch = q => {
    router.push(`${redirectToPage(q, router)}`);
  };

  // useEffect(() => {
  //   posthog.capture('Overview Page Event', {
  //     property: 'overview page visited'
  //   });
  // }, []);

  return (
    <>
      <MainLayout navs={navs} healthSystem={healthSystem}>
        <Head>
          <title> {healthSystem?.label || 'Overview'}</title>
        </Head>
        {fromActivity && <FromMyActivity />}
        <FilterComponent
          handleSearch={handleSearch}
          disabled={fromActivity}
          query="2e8abbc262016d94e6d660ca02986e316b63fd94"
          healthSystem={healthSystem}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <MedicationSpend healthSystemId={healthSystemId} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <MedicationSpendPerPatient healthSystemId={healthSystemId} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <NewPatient healthSystemId={healthSystemId} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={4}>
            <SavingOpportunity healthSystemId={healthSystemId} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={4}>
            <UtilizationTrend healthSystemId={healthSystemId} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={4}>
            <PhysicianTrend healthSystemId={healthSystemId} />
          </Grid>

          {isFetching || isLoading ? (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div
                style={{
                  height: '200px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: '#fff'
                }}
              >
                <CircularLoader />
              </div>
            </Grid>
          ) : (
            <OverviewAllCharts
              healthSystemId={healthSystemId}
              topThreeHospitals={
                topThreeHospitals?.length ? topThreeHospitals : []
              }
              hospitalOptions={hospitalOptionsData?.hospitalOption}
            />
          )}

          <Grid item xs={12}>
            <HospitalsTable healthSystemId={healthSystemId} />
          </Grid>
          <Grid item xs={12}>
            <OverviewInsights healthSystemId={healthSystemId} />
          </Grid>
        </Grid>
      </MainLayout>
    </>
  );
};

export async function getServerSideProps() {
  const res = await getHealthSystem();
  const healthSystemId = res.healthSystemOption[0].value;
  const data = await getHospitalsList({ healthSystemId });

  return {
    props: {
      hospitalOptionsData: data,
      healthSystem: res.healthSystemOption[0]
    }
  };
}

export default OverviewHealthSystem;
OverviewHealthSystem.auth = true;
