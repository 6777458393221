import { makeStyles } from 'tss-react/mui';
import React, { useState } from 'react';

import dynamic from 'next/dynamic';
import NewTooltip from '../../shared/Tooltip';
import UseRadioGroup from '../../shared/RadioButtons';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { Card } from '@mui/material';

import { Circle } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretLeft } from '@fortawesome/free-solid-svg-icons';
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  TextField,
  Tooltip
} from '@mui/material';
import CircularLoader from '../../shared/CircularLoader';
import { getFormattedNumber } from '../../../utils/currency.utils';
import { checkRequiredResponsiveSizeUsingCssMediaQuery } from '../../../utils/resposive.utils';
import {
  getDayMonth,
  getDayMonthYear,
  getMonthShortYear
} from '../../../utils/date.utils';

const Chart = dynamic(() => import('react-apexcharts'), { ssr: false });
const colors = ['#387ED0', '#57D57E', '#FF5733'];
const ApexBarChartDistributed = ({
  data,
  topThreeHospitals,
  selectedRange,
  setSelectedRange,
  hospitalOptions,
  chartData,
  handleRange,
  range = [],
  setRange,
  chartDataLength,
  selectedHospitals,
  setOnChangeHospitals,
  fetching,
  originalTopThreeHospitals,
  prefix,
  stepUp,
  id,
  initialRange
}) => {
  const [open, setOpen] = useState(false);
  const [moreOpen, setMoreOpen] = useState(false);

  const handleTooltipOpen = () => {
    setOpen(!open);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleMoreTooltipOpen = () => {
    setMoreOpen(!moreOpen);
  };

  const handleMoreTooltipClose = () => {
    setMoreOpen(false);
  };

  const getPreviousDataRange = range => {
    if (range[0] - stepUp > 0 && range[1] - stepUp > 0) {
      return [range[0] - stepUp, range[1] - stepUp];
    } else if (range[1] - stepUp > 0 && range[0] - stepUp <= 0) {
      return [0, range[1] - stepUp];
    } else {
      return range;
    }
  };

  const prevDataRange = getPreviousDataRange(range);

  const getFormattedSeries = series => {
    return series?.map(item => {
      return {
        name: item.name,
        data: item.data
      };
    });
  };

  const numberOfBars = chartData.series?.length || 3;
  const values = [];
  const categories = chartData?.dates.map((item, i) => {
    if (numberOfBars === 1) {
      if (i % 2 !== 0) {
        values.push(getDayMonthYear(item));
      }
      return getDayMonthYear(item);
    } else {
      return getDayMonthYear(item);
    }
  });

  const { classes } = useStyles();
  const props = {
    series: getFormattedSeries(chartData?.series) || [],
    options: {
      colors: ['#387ED0', '#57D57E', '#FF5733'],
      chart: {
        type: 'bar',
        height: 280,
        id: `${id}`,
        width: 10,
        stacked: false,
        toolbar: {
          show: false,
          tools: {
            download: true,
            selection: false,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: false,
            reset: false
          }
        },
        redrawOnWindowResize: true,
        redrawOnParentResize: true,
        zoom: {
          enabled: true
        },
        events: {
          mounted: function (chartContext, config) {
            loadBackgrounds();
          },
          updated: function (chartContext, config) {
            loadBackgrounds();
          }
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 3,
          columnWidth: `${20 + numberOfBars * 10}%`,
          rangeBarOverlap: true,
          rangeBarGroupRows: false,
          dataLabels: {
            enabled: false
          },
          colors: {
            backgroundBarColors: ['#f1f3f6']
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 4,
        colors: ['transparent']
      },
      xaxis: {
        type: 'category',
        categories: categories,

        lines: {
          show: true
        },
        tickAmount: 'dataPoints',
        tickPlacement: 'between',
        axisBorder: {
          show: true,
          color: '#E0E4ED',
          height: 1,
          width: '100%',
          offsetX: 0,
          offsetY: 36
        },
        axisTicks: {
          show: true,
          borderType: 'solid',
          color: '#e0e4ed',
          height: 20,
          offsetX: numberOfBars === 1 ? 15 : 28,
          offsetY: -15
        },
        labels: {
          offsetY: numberOfBars === 1 ? 0 : -5,
          offsetX: numberOfBars === 1 ? 0 : 0,
          style: {
            cssClass: 'apexcharts-axis-labels'
          },
          formatter: function (value) {
            return values?.some(it => it === value)
              ? ''
              : selectedRange === 'Monthly'
                ? getMonthShortYear(value)
                : getDayMonth(value);
          }
        }
      },
      grid: {
        borderColor: '#f0f0f0',
        strokeDashArray: 2,
        xaxis: {
          lines: {
            show: true
          }
        }
      },
      yaxis: {
        lines: {
          show: true
        },
        opposite: true,
        labels: {
          formatter: function (val, index) {
            return `${prefix || ''}${getFormattedNumber(val)}`;
          }
        },
        stroke: {
          color: 'red'
        }
      },
      tooltip: {
        shared: false,
        x: {
          formatter: function (val, s) {
            return val;
          }
        },
        y: {
          formatter: function (val) {
            return getFormattedNumber(val);
          }
        }
      },
      legend: {
        position: 'top',
        offsetY: 10,
        show: false
      },
      fill: {
        opacity: 1
      }
    }
  };

  const createSvgLines = (position, height, width, count, i) => {
    const barWidth = width / count;
    const x = parseFloat(position) + (i + 1) * barWidth * 0.95;
    var newLine = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'line'
    );
    newLine.setAttribute('x1', x);
    newLine.setAttribute('y1', '0');
    newLine.setAttribute('x2', x);
    newLine.setAttribute('y2', height);
    newLine.setAttribute('stroke', 'white');
    newLine.setAttribute('atrokewidth', '3');
    return newLine;
  };

  const loadBackgrounds = () => {
    const element = document.getElementById(`apexcharts${id}`);
    const backgroundBars =
      element?.getElementsByClassName('apexcharts-backgroundBar') || [];
    const height = backgroundBars[0]?.getAttribute('height');
    const width = backgroundBars[0]?.getAttribute('width');
    const count = props.series?.length;
    if (backgroundBars.length) {
      [...Array(backgroundBars.length)].forEach((item, x) => {
        const backWidth = parseFloat(width);
        const parent = backgroundBars[x]?.parentNode;
        const position = backgroundBars[x].getAttribute('x');
        const backElement = backgroundBars[x];
        backElement.setAttribute('width', backWidth - 5);
        backElement.style.transform = 'translate(2px, 0px)';
        [...Array(count - 1)].forEach((item, i) => {
          parent.appendChild(createSvgLines(position, height, width, count, i));
        });
      });
    }
  };

  const smallScreenMatched =
    checkRequiredResponsiveSizeUsingCssMediaQuery('(max-width:550px)');

  return (
    <Card className={classes.card} data-cy={data?.title}>
      <div>
        <div className={classes.header}>
          <div className={classes.headerleft}>
            <div className={classes.title}>{data?.title}</div>
          </div>

          <NewTooltip
            placement="bottom-end"
            handleTooltipClose={handleTooltipClose}
            handleTooltipOpen={handleTooltipOpen}
            open={open}
            title={
              <UseRadioGroup
                data={[
                  { value: 'Daily', label: 'Daily' },
                  { value: 'Weekly', label: 'Weekly' },
                  { value: 'Monthly', label: 'Monthly' }
                ]}
                setValue={value => {
                  setSelectedRange(value);
                  setRange([0, 12]);
                }}
                value={selectedRange}
              />
            }
          >
            <div
              className={classes.selector}
              style={{ display: 'flex', alignItems: 'center' }}
              data-cy="range"
            >
              {selectedRange}
              <ArrowDropDown />
            </div>
          </NewTooltip>
        </div>
        {false ? (
          <div
            style={{
              height: '100px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <CircularLoader />
          </div>
        ) : (
          <div style={{ position: 'relative', paddingTop: 20 }}>
            <div
              style={{
                display: 'flex',
                position: 'absolute',
                left: 20,
                right: 0,
                top: 5,
                zIndex: 1
              }}
            >
              {!smallScreenMatched &&
                topThreeHospitals?.map((hospital, index) => (
                  <p
                    key={hospital?.value}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: '14px',
                      fontWeight: 500,
                      color: colors[index],
                      margin: '10px'
                    }}
                  >
                    <Circle
                      style={{
                        fontSize: '14px',
                        marginRight: 3,
                        color: colors[index]
                      }}
                    />{' '}
                    {hospital?.label}
                  </p>
                ))}
              {topThreeHospitals?.length ||
                (!topThreeHospitals?.length &&
                  originalTopThreeHospitals?.length) ? (
                <>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <NewTooltip
                      open={moreOpen}
                      handleTooltipOpen={handleMoreTooltipOpen}
                      handleTooltipClose={handleMoreTooltipClose}
                      placement="bottom-end"
                      title={
                        <MoreComponentDetails
                          hospitalOptions={hospitalOptions}
                          selectedHospitals={selectedHospitals}
                          setOnChangeHospitals={setOnChangeHospitals}
                          smallScreenMatched={smallScreenMatched}
                          data-cy={`filter-${data.title}`}
                        />
                      }
                    >
                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          color: '#387ED0',
                          border: '1px solid #387ED0',
                          borderRadius: ' 4px',
                          padding: '0px 8px',
                          gap: ' 2px',
                          fontWeight: 500,
                          fontSize: '13px',
                          lineHeight: '150%'
                        }}
                        data-cy="more-hospital"
                      >
                        {!topThreeHospitals?.length &&
                          originalTopThreeHospitals?.length
                          ? 'Select Hospitals'
                          : smallScreenMatched
                            ? 'Hospitals'
                            : 'More'}
                        <ArrowDropDown style={{ fontSize: '14px' }} />
                      </span>
                    </NewTooltip>
                  </div>
                  {topThreeHospitals?.length ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        flex: 1,
                        padding: '0px 8px'
                      }}
                    >
                      <Tooltip title={`Show previous data`}>
                        <IconButton
                          onClick={() => {
                            return handleRange(
                              'increase',
                              setRange,
                              range,
                              chartDataLength,
                              initialRange
                            );
                          }}
                          disabled={range[1] === chartDataLength}
                          style={{
                            width: 22,
                            height: 20,
                            backgroundColor: '#eff5ff',
                            borderRadius: 1,
                            padding: 0
                          }}
                          data-cy="show-previous"
                        >
                          <FontAwesomeIcon
                            icon={faCaretLeft}
                            width={6}
                            style={{
                              color:
                                range[1] === chartDataLength
                                  ? '#7d7d7d'
                                  : '#008ffb'
                            }}
                          />
                        </IconButton>
                      </Tooltip>

                      <Tooltip
                        title={
                          prevDataRange[0] !== 0 && prevDataRange[1] !== stepUp
                            ? `Show next data`
                            : ''
                        }
                      >
                        <IconButton
                          onClick={() =>
                            handleRange(
                              'decrease',
                              setRange,
                              range,
                              chartDataLength,
                              initialRange
                            )
                          }
                          disabled={range[0] === 0}
                          style={{
                            width: 22,
                            height: 20,
                            backgroundColor: '#eff5ff',
                            borderRadius: 1,
                            padding: 0
                          }}
                          data-cy="show-next"
                        >
                          <FontAwesomeIcon
                            icon={faCaretRight}
                            width={6}
                            style={{
                              color: range[0] === 0 ? '#7d7d7d' : '#008ffb'
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  ) : (
                    ''
                  )}
                </>
              ) : (
                ''
              )}
            </div>
            {fetching ? (
              <div
                style={{
                  height: '305px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <CircularLoader />
              </div>
            ) : (
              <Chart
                options={props.options}
                series={props.series}
                type="bar"
                style={{ marginTop: 10 }}
                // width="500"
                height={280}
              />
            )}
          </div>
        )}
      </div>
    </Card>
  );
};

// style constant
const useStyles = makeStyles()(theme => {
  return {
    button: {
      textAlign: 'left',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    borderRight: {
      borderRight: '1px solid #EEEEEE'
    },
    card: {
      height: '100%'
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: '1px solid #f1f3f6',
      width: '100%',
      padding: 10,
      minHeight: 50
    },
    headerLeft: {},
    selector: {
      backgroundColor: '#eff5ff',
      padding: '2px 12px',
      borderRadius: 10,
      color: theme.palette.primary?.dark
    },
    content: {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      borderTop: '1px solid #f1f3f6',
      padding: 30,
      minHeight: 165
    },
    contentRow: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      borderTop: '1px solid #f1f3f6',
      padding: 30,
      minHeight: 165
    },
    footer: {
      display: 'flex',
      flexDirection: 'row',
      borderTop: '1px solid #f1f3f6',
      padding: 10,
      height: 100
    },
    title: {
      fontSize: 20,
      fontWeight: '500',
      color: '#465671'
    },
    icon: {
      backgroundColor: '#eff5ff',
      borderRadius: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 35,
      height: 35
    },
    value: {
      fontSize: 30,
      paddingTop: 10,
      paddingBottom: 0,
      fontWeight: 'bold',
      color: '#387ED0'
    },
    footerItem: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      flex: 1,
      padding: '0px 0'
    },
    footerValue: {
      fontSize: 30,
      fontWeight: 'bold',
      paddingTop: 0
    }
  };
});

export const MoreComponentDetails = ({
  hospitalOptions,
  selectedHospitals,
  setOnChangeHospitals,
  smallScreenMatched,
  ...props
}) => {
  const [sortBy, setSortBy] = useState('A-Z');
  const [inputValue, setInputValue] = useState('');

  const handleChange = (event, hospital) => {
    if (event.target.checked) {
      selectedHospitals.length < 3 &&
        setOnChangeHospitals([...selectedHospitals, hospital]);
    } else {
      selectedHospitals.length > 1 &&
        setOnChangeHospitals(
          selectedHospitals?.filter(item => item?.value !== hospital?.value)
        );
    }
  };

  const getResults = (hospitalOptions, inputValue) => {
    return hospitalOptions?.filter(hospital => {
      if (inputValue === '') return hospitalOptions;
      return hospital?.label?.toLowerCase().includes(inputValue?.toLowerCase());
    });
  };

  const getHospitalOptions = (sortBy, inputValue) => {
    if (sortBy === 'A-Z') {
      const results = getResults(hospitalOptions, inputValue);
      return results?.sort(function (a, b) {
        const textA = a?.label?.toUpperCase();
        const textB = b?.label?.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    } else if (sortBy === 'Z-A') {
      const results = getResults(hospitalOptions, inputValue);

      return results?.sort(function (a, b) {
        const textA = a?.label?.toUpperCase();
        const textB = b?.label?.toUpperCase();
        return textA < textB ? 1 : textA > textB ? -1 : 0;
      });
    } else {
      const results = getResults(hospitalOptions, inputValue);
      return results;
    }
  };

  const handleClickSortBy = value => {
    setSortBy(value);
  };

  return (
    <div
      style={{
        padding: '1px 10px',
        width: smallScreenMatched ? 250 : '100%'
      }}
      {...props}
    >
      <span
        style={{
          display: 'flex',
          margin: '10px 0px'
        }}
      >
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
            color: sortBy === 'A-Z' ? '#FFF' : '#387ED0',
            // border: '1px solid #387ED0',
            borderRadius: ' 4px',
            padding: '5px 10px',
            gap: ' 2px',
            fontWeight: 500,
            fontSize: '13px',
            lineHeight: '150%',
            marginRight: '10px',
            minWidth: 120,
            background: sortBy === 'A-Z' ? '#387ED0' : 'rgb(243, 248, 255)',
            cursor: 'pointer'
          }}
          onClick={() => handleClickSortBy('A-Z')}
          data-cy="sort-a-z"
        >
          Sort By A-Z
          <ArrowDropDown style={{ fontSize: '20px' }} />
        </span>

        <span
          style={{
            display: 'flex',
            alignItems: 'center',
            color: sortBy === 'Z-A' ? '#FFF' : '#387ED0',
            // border: '1px solid #387ED0',
            borderRadius: ' 4px',
            padding: '5px 10px',
            gap: ' 2px',
            fontWeight: 500,
            fontSize: '13px',
            lineHeight: '150%',
            minWidth: 120,
            background: sortBy === 'Z-A' ? '#387ED0' : 'rgb(243, 248, 255)',
            cursor: 'pointer'
          }}
          onClick={() => handleClickSortBy('Z-A')}
          data-cy="sort-z-a"
        >
          Sort By Z-A
          <ArrowDropDown style={{ fontSize: '20px' }} />
        </span>
      </span>
      <TextField
        style={{ width: '100%' }}
        id="outlined-basic"
        placeholder="Search hospitals"
        variant="outlined"
        size="small"
        onChange={e => setInputValue(e?.target?.value)}
        data-cy="search-box"
      />
      <p style={{ fontWeight: 600, fontSize: '13px', marginBottom: 0 }}>
        Hospitals
      </p>
      <div style={{ height: '110px', overflow: 'auto' }}>
        <div
          style={{ display: 'flex', flexDirection: 'column' }}
          data-cy="search-results"
        >
          {getHospitalOptions(sortBy, inputValue)?.map(option => (
            <FormControlLabel
              sx={{ marginBottom: '-10px' }}
              key={option?.label}
              control={
                <Checkbox
                  checked={selectedHospitals?.some(
                    selected => selected?.value === option?.value
                  )}
                  size="small"
                  onChange={event => handleChange(event, option)}
                />
              }
              label={<span>{option?.label}</span>}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ApexBarChartDistributed;
